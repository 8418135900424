import { IToast } from '@chakra-ui/core'
import { Book, HelpCircle, Home, LogOut, Server, User, Package } from 'react-feather'
import { IWorkBook } from '../types/servicesCatalog.type'

// navigation constants
export type SubNavItem = {
  to: string
  title: string
  subMenus?: SubNavItem[]
}
// navigation constants
export type NavItem = {
  to: string
  title: string
  Icon: React.FC
  subMenus?: SubNavItem[]
  permissionLevel?: string[]
}

const subMenuGenerator = (to: string, title: string) => ({ to, title })

export const getNavItems = (workbooks: IWorkBook[]): NavItem[] => [
  {
    ...subMenuGenerator('/dashboard', 'Dashboard'),
    Icon: Home,
    subMenus: [],
    permissionLevel: [
      ROLES.ADMIN,
      ROLES.PHLEBO_TEAM,
      ROLES.NON_CLINICAL_MOC_AGENTS,
      ROLES.CLINICAL_OR_PHARMACY_TEAM,
      ROLES.FINANCE_OR_COMMERCIAL,
      ROLES.BRAND_AND_MARKETING,
      ROLES.B2B_AND_B2B2C,
      ROLES.DEMO
    ]
  },
  {
    ...subMenuGenerator('/customer', 'Customer'),
    Icon: User,
    subMenus: [],
    permissionLevel: [
      ROLES.ADMIN,
      ROLES.PHLEBO_TEAM,
      ROLES.NON_CLINICAL_MOC_AGENTS,
      ROLES.CLINICAL_OR_PHARMACY_TEAM,
      ROLES.FINANCE_OR_COMMERCIAL,
      ROLES.BRAND_AND_MARKETING,
      ROLES.B2B_AND_B2B2C,
      ROLES.DEMO
    ]
  },
  {
    ...subMenuGenerator('/appointment', 'Appointment'),
    Icon: Book,
    subMenus: [],
    permissionLevel: [
      ROLES.ADMIN,
      ROLES.PHLEBO_TEAM,
      ROLES.NON_CLINICAL_MOC_AGENTS,
      ROLES.CLINICAL_OR_PHARMACY_TEAM,
      ROLES.FINANCE_OR_COMMERCIAL,
      ROLES.BRAND_AND_MARKETING,
      ROLES.B2B_AND_B2B2C,
      ROLES.DEMO
    ]
  },
  {
    ...subMenuGenerator('/epharmacy', 'E-Pharmacy'),
    Icon: Package,
    subMenus: [
      {
        ...subMenuGenerator('/epharmacy/inventory', 'Inventory'),
        subMenus: [
          subMenuGenerator('/epharmacy/inventory', 'View'),
          subMenuGenerator('/epharmacy/create/inventory-item', 'Create')
        ]
      },
      {
        ...subMenuGenerator('/epharmacy/medicines', 'Medicines'),
        subMenus: [
          subMenuGenerator('/epharmacy/medicines', 'View'),
          subMenuGenerator('/epharmacy/create/medicine', 'Create')
        ]
      },
      {
        ...subMenuGenerator('/epharmacy/orders', 'Orders'),
        subMenus: [
          subMenuGenerator('/epharmacy/orders', 'View'),
          subMenuGenerator('/epharmacy/create/order', 'Create')
        ]
      },
      {
        ...subMenuGenerator('/epharmacy/prescriptions', 'Prescriptions'),
        subMenus: [
          subMenuGenerator('/epharmacy/prescriptions', 'View'),
          subMenuGenerator('/epharmacy/create/prescription', 'Create')
        ]
      },
      {
        ...subMenuGenerator('/epharmacy/vendors', 'Users'),
        subMenus: [
          subMenuGenerator('/epharmacy/vendors', 'View'),
          subMenuGenerator('/epharmacy/create/vendor', 'Create')
        ]
      },
      {
        ...subMenuGenerator('/epharmacy/widgets', 'Widgets'),
        subMenus: [
          subMenuGenerator('/epharmacy/widgets', 'View'),
          subMenuGenerator('/epharmacy/create/widget', 'Create')
        ]
      },
      subMenuGenerator('/epharmacy/disease', 'Disease'),
      subMenuGenerator('/epharmacy/doctor', 'Doctor')
    ],
    permissionLevel: [
      ROLES.ADMIN,
      ROLES.PHLEBO_TEAM,
      ROLES.NON_CLINICAL_MOC_AGENTS,
      ROLES.CLINICAL_OR_PHARMACY_TEAM,
      ROLES.FINANCE_OR_COMMERCIAL,
      ROLES.BRAND_AND_MARKETING,
      ROLES.B2B_AND_B2B2C,
      ROLES.DEMO
    ]
  },
  {
    ...subMenuGenerator('/service-and-product', 'Service & Product'),
    Icon: Server,
    subMenus: [
      {
        ...subMenuGenerator('/service-and-product/1/', 'Providers, Clinics & Facilities'),
        subMenus: [
          {
            ...subMenuGenerator(
              '/service-and-product/1/providers-management',
              'Providers Management'
            )
          },
          {
            ...subMenuGenerator(
              '/service-and-product/1/clinic-management',
              'Clinic Facility Management'
            ),
            subMenus: [
              subMenuGenerator('/service-and-product/1/clinic-management/clinics', 'Facilities'),
              subMenuGenerator(
                '/service-and-product/1/clinic-management/clinics-branches',
                'Facility Branches (coming soon)'
              )
            ]
          }
        ]
      },
      {
        ...subMenuGenerator('/service-and-product/2', 'Providers, Services And Products'),
        subMenus: [
          subMenuGenerator('/service-and-product/2/packages', 'Packages'),
          ...workbooks.map((workbook) =>
            subMenuGenerator(`/service-and-product/2/product/${workbook.id}`, workbook.description)
          )
        ]
      }
    ],
    permissionLevel: [
      ROLES.ADMIN,
      ROLES.PHLEBO_TEAM,
      ROLES.NON_CLINICAL_MOC_AGENTS,
      ROLES.CLINICAL_OR_PHARMACY_TEAM,
      ROLES.FINANCE_OR_COMMERCIAL,
      ROLES.BRAND_AND_MARKETING,
      ROLES.B2B_AND_B2B2C,
      ROLES.DEMO
    ]
  },
  {
    to: `/enquiry`,
    title: 'Enquiry',
    Icon: HelpCircle,
    subMenus: [],
    permissionLevel: [
      ROLES.ADMIN,
      ROLES.PHLEBO_TEAM,
      ROLES.NON_CLINICAL_MOC_AGENTS,
      ROLES.CLINICAL_OR_PHARMACY_TEAM,
      ROLES.FINANCE_OR_COMMERCIAL,
      ROLES.BRAND_AND_MARKETING,
      ROLES.B2B_AND_B2B2C,
      ROLES.DEMO
    ]
  }
]

export const LOGOUT_NAV_ITEM: NavItem[] = [
  {
    ...subMenuGenerator('/', 'Logout'),
    Icon: LogOut
  }
]

// toast notification constants
export const SUCCESS_TOAST: IToast = {
  duration: 5000,
  isClosable: true,
  title: 'Success!',
  status: 'success',
  position: 'bottom-right'
}

export const PATIENT_BASE_URL = process.env.REACT_APP_KEY_PATIENT_BASE_URL
export const MOC_BASE_URL = process.env.REACT_APP_KEY_MOC_BASE_URL
export const APPOINTMENT_BASE_URL = process.env.REACT_APP_KEY_APPOINTMENT_BASE_URL
export const SERVICES_CATALOG_BASE_URL = process.env.REACT_APP_KEY_SERVICES_CATALOG_BASE_URL
export const CALL_CENTER_BASE_URL = process.env.REACT_APP_KEY_CALL_CENTER_BASE_URL
export const KEY_CLOAK_BASE_URL = process.env.REACT_APP_KEY_CLOAK_URL

export const DOCUMENT_TYPE = {
  image: 1,
  invoice: 2,
  medicalRecord: 3,
  doctorImage: '090b6b7c-f947-4e80-899e-fda08cfb8fed',
  productImage: 'a041d208-e38b-4d83-a0d2-729e4342e157'
}

export const ROLES = {
  B2B_AND_B2B2C: 'B2B & B2B2C',
  BRAND_AND_MARKETING: 'Brand and Marketing',
  CALL_CENTRE: 'Call centre',
  CLINICAL_OR_PHARMACY_TEAM: 'Clinical Team (+Pharmacy)',
  FINANCE_OR_COMMERCIAL: 'Finance/Commercial',
  MOC: 'MOC',
  NON_CLINICAL_MOC_AGENTS: 'Non-clinical MOC Agents',
  OFFLINE_ACCESS: 'offline_access',
  PHLEBO_TEAM: 'Phlebo Team',
  UMA_AUTHORIZATION: 'uma_authorization',
  ADMIN: 'Admin',
  DEMO: 'Demo'
}

export const PERMISSION_LEVEL_MICRO_SERVICE = {
  //://
  DASHBOARD_VIEW: 'dashboard_view',
  //://
  CUSTOMER_PROFILE_INFO_VIEW: 'customer_profile_info_view',
  CUSTOMER_PROFILE_INFO_CREATE: 'customer_profile_info_create',
  CUSTOMER_PROFILE_INFO_UPDATE: 'customer_profile_info_update',
  CUSTOMER_PROFILE_INFO_DEACTIVATE: 'customer_profile_info_deactivate',
  // /:// //
  CUSTOMER_APPOINTMENT_VIEW: 'customer_appointment_view',
  CUSTOMER_APPOINTMENT_CREATE: 'customer_appointment_create',
  CUSTOMER_APPOINTMENT_UPDATE: 'customer_appointment_update',
  // /:// //
  CUSTOMER_ENQUIRES_VIEW: 'customer_enquires_view',
  CUSTOMER_ENQUIRES_CREATE: 'customer_enquires_create',
  CUSTOMER_ENQUIRES_UPDATE: 'customer_enquires_update',
  // /:// //
  CUSTOMER_ORDER_VIEW: 'customer_order_view',
  CUSTOMER_ORDER_CREATE: 'customer_order_create',
  CUSTOMER_ORDER_UPDATE: 'customer_order_update',
  // /:// //
  CUSTOMER_PATIENT_VIEW: 'customer_patient_view',
  CUSTOMER_PATIENT_CREATE: 'customer_patient_create',
  CUSTOMER_PATIENT_UPDATE: 'customer_patient_update',
  // /:// //
  APPOINTMENTS_VIEW: 'appointments_view',
  APPOINTMENTS_CREATE: 'appointments_create',
  APPOINTMENTS_DEACTIVATE: 'appointments_deactivate',
  APPOINTMENTS_UPDATE: 'appointments_update',
  // /:// //
  SERVICE_AND_PRODUCT_VIEW: 'service_and_product_view',
  SERVICE_AND_PRODUCT_CREATE: 'service_and_product_create',
  SERVICE_AND_PRODUCT_UPDATE: 'service_and_product_update',
  SERVICE_AND_PRODUCT_DEACTIVATE: 'service_and_product_deactivate',
  // /:// //
  PACKAGES_VIEW: 'packages_view',
  PACKAGES_CREATE: 'packages_create',
  PACKAGES_UPDATE: 'packages_update',
  PACKAGES_DEACTIVATE: 'packages_deactivate',
  // /:// //
  SPECIALISTS_VIEW: 'specialists_view',
  SPECIALISTS_CREATE: 'specialists_create',
  SPECIALISTS_UPDATE: 'specialists_update',
  SPECIALISTS_DEACTIVATE: 'specialists_deactivate'
}

export const PERMISSION_LEVEL = {
  B2B_AND_B2B2C: [
    PERMISSION_LEVEL_MICRO_SERVICE.DASHBOARD_VIEW,

    PERMISSION_LEVEL_MICRO_SERVICE.CUSTOMER_PROFILE_INFO_VIEW,
    PERMISSION_LEVEL_MICRO_SERVICE.CUSTOMER_PROFILE_INFO_CREATE,

    PERMISSION_LEVEL_MICRO_SERVICE.CUSTOMER_ENQUIRES_VIEW,
    PERMISSION_LEVEL_MICRO_SERVICE.CUSTOMER_ENQUIRES_CREATE,

    PERMISSION_LEVEL_MICRO_SERVICE.CUSTOMER_ORDER_VIEW,

    PERMISSION_LEVEL_MICRO_SERVICE.APPOINTMENTS_VIEW,

    PERMISSION_LEVEL_MICRO_SERVICE.SERVICE_AND_PRODUCT_VIEW,

    PERMISSION_LEVEL_MICRO_SERVICE.PACKAGES_VIEW,

    PERMISSION_LEVEL_MICRO_SERVICE.SPECIALISTS_VIEW
  ],
  BRAND_AND_MARKETING: [
    PERMISSION_LEVEL_MICRO_SERVICE.DASHBOARD_VIEW,

    PERMISSION_LEVEL_MICRO_SERVICE.CUSTOMER_PROFILE_INFO_VIEW,
    PERMISSION_LEVEL_MICRO_SERVICE.CUSTOMER_PROFILE_INFO_CREATE,

    PERMISSION_LEVEL_MICRO_SERVICE.CUSTOMER_ENQUIRES_VIEW,
    PERMISSION_LEVEL_MICRO_SERVICE.CUSTOMER_ENQUIRES_CREATE,

    PERMISSION_LEVEL_MICRO_SERVICE.CUSTOMER_ORDER_VIEW,

    PERMISSION_LEVEL_MICRO_SERVICE.SERVICE_AND_PRODUCT_VIEW,

    PERMISSION_LEVEL_MICRO_SERVICE.PACKAGES_VIEW,

    PERMISSION_LEVEL_MICRO_SERVICE.SPECIALISTS_VIEW
  ],
  // CALL_CENTRE: [PERMISSION_LEVEL_MICRO_SERVICE.DASHBOARD_VIEW],
  CLINICAL_OR_PHARMACY_TEAM: [
    PERMISSION_LEVEL_MICRO_SERVICE.DASHBOARD_VIEW,

    PERMISSION_LEVEL_MICRO_SERVICE.CUSTOMER_PROFILE_INFO_VIEW,
    PERMISSION_LEVEL_MICRO_SERVICE.CUSTOMER_PROFILE_INFO_CREATE,
    PERMISSION_LEVEL_MICRO_SERVICE.CUSTOMER_PROFILE_INFO_UPDATE,

    PERMISSION_LEVEL_MICRO_SERVICE.CUSTOMER_APPOINTMENT_VIEW,
    PERMISSION_LEVEL_MICRO_SERVICE.CUSTOMER_APPOINTMENT_CREATE,
    PERMISSION_LEVEL_MICRO_SERVICE.CUSTOMER_APPOINTMENT_UPDATE,

    PERMISSION_LEVEL_MICRO_SERVICE.CUSTOMER_ENQUIRES_VIEW,
    PERMISSION_LEVEL_MICRO_SERVICE.CUSTOMER_ENQUIRES_CREATE,
    PERMISSION_LEVEL_MICRO_SERVICE.CUSTOMER_ENQUIRES_UPDATE,

    PERMISSION_LEVEL_MICRO_SERVICE.CUSTOMER_ORDER_VIEW,
    PERMISSION_LEVEL_MICRO_SERVICE.CUSTOMER_ORDER_UPDATE,

    PERMISSION_LEVEL_MICRO_SERVICE.CUSTOMER_PATIENT_VIEW,
    PERMISSION_LEVEL_MICRO_SERVICE.CUSTOMER_PATIENT_CREATE,
    PERMISSION_LEVEL_MICRO_SERVICE.CUSTOMER_PATIENT_UPDATE,

    PERMISSION_LEVEL_MICRO_SERVICE.APPOINTMENTS_VIEW,
    PERMISSION_LEVEL_MICRO_SERVICE.APPOINTMENTS_UPDATE,
    PERMISSION_LEVEL_MICRO_SERVICE.APPOINTMENTS_CREATE,
    PERMISSION_LEVEL_MICRO_SERVICE.APPOINTMENTS_DEACTIVATE,

    PERMISSION_LEVEL_MICRO_SERVICE.SERVICE_AND_PRODUCT_VIEW,
    PERMISSION_LEVEL_MICRO_SERVICE.SERVICE_AND_PRODUCT_UPDATE,
    PERMISSION_LEVEL_MICRO_SERVICE.SERVICE_AND_PRODUCT_CREATE,
    PERMISSION_LEVEL_MICRO_SERVICE.SERVICE_AND_PRODUCT_DEACTIVATE,

    PERMISSION_LEVEL_MICRO_SERVICE.PACKAGES_VIEW,
    PERMISSION_LEVEL_MICRO_SERVICE.PACKAGES_UPDATE,
    PERMISSION_LEVEL_MICRO_SERVICE.PACKAGES_CREATE,
    PERMISSION_LEVEL_MICRO_SERVICE.PACKAGES_DEACTIVATE,

    PERMISSION_LEVEL_MICRO_SERVICE.SPECIALISTS_VIEW,
    PERMISSION_LEVEL_MICRO_SERVICE.SPECIALISTS_UPDATE,
    PERMISSION_LEVEL_MICRO_SERVICE.SPECIALISTS_CREATE,
    PERMISSION_LEVEL_MICRO_SERVICE.SPECIALISTS_DEACTIVATE
  ],
  FINANCE_OR_COMMERCIAL: [
    PERMISSION_LEVEL_MICRO_SERVICE.DASHBOARD_VIEW,

    PERMISSION_LEVEL_MICRO_SERVICE.CUSTOMER_APPOINTMENT_VIEW,

    PERMISSION_LEVEL_MICRO_SERVICE.CUSTOMER_ENQUIRES_CREATE,

    PERMISSION_LEVEL_MICRO_SERVICE.APPOINTMENTS_VIEW,
    PERMISSION_LEVEL_MICRO_SERVICE.APPOINTMENTS_UPDATE,
    PERMISSION_LEVEL_MICRO_SERVICE.APPOINTMENTS_CREATE,
    PERMISSION_LEVEL_MICRO_SERVICE.APPOINTMENTS_DEACTIVATE,

    PERMISSION_LEVEL_MICRO_SERVICE.SERVICE_AND_PRODUCT_VIEW,
    PERMISSION_LEVEL_MICRO_SERVICE.SERVICE_AND_PRODUCT_UPDATE,
    PERMISSION_LEVEL_MICRO_SERVICE.SERVICE_AND_PRODUCT_CREATE,
    PERMISSION_LEVEL_MICRO_SERVICE.SERVICE_AND_PRODUCT_DEACTIVATE,

    PERMISSION_LEVEL_MICRO_SERVICE.PACKAGES_VIEW,
    PERMISSION_LEVEL_MICRO_SERVICE.PACKAGES_UPDATE,
    PERMISSION_LEVEL_MICRO_SERVICE.PACKAGES_CREATE,
    PERMISSION_LEVEL_MICRO_SERVICE.PACKAGES_DEACTIVATE,

    PERMISSION_LEVEL_MICRO_SERVICE.SPECIALISTS_VIEW,
    PERMISSION_LEVEL_MICRO_SERVICE.SPECIALISTS_UPDATE,
    PERMISSION_LEVEL_MICRO_SERVICE.SPECIALISTS_CREATE,
    PERMISSION_LEVEL_MICRO_SERVICE.SPECIALISTS_DEACTIVATE
  ],
  // MOC: [PERMISSION_LEVEL_MICRO_SERVICE.DASHBOARD_VIEW],
  NON_CLINICAL_MOC_AGENTS: [
    PERMISSION_LEVEL_MICRO_SERVICE.DASHBOARD_VIEW,

    PERMISSION_LEVEL_MICRO_SERVICE.CUSTOMER_PROFILE_INFO_VIEW,
    PERMISSION_LEVEL_MICRO_SERVICE.CUSTOMER_PROFILE_INFO_CREATE,
    PERMISSION_LEVEL_MICRO_SERVICE.CUSTOMER_PROFILE_INFO_UPDATE,

    PERMISSION_LEVEL_MICRO_SERVICE.CUSTOMER_APPOINTMENT_VIEW,
    PERMISSION_LEVEL_MICRO_SERVICE.CUSTOMER_APPOINTMENT_CREATE,
    PERMISSION_LEVEL_MICRO_SERVICE.CUSTOMER_APPOINTMENT_UPDATE,

    PERMISSION_LEVEL_MICRO_SERVICE.CUSTOMER_ENQUIRES_VIEW,
    PERMISSION_LEVEL_MICRO_SERVICE.CUSTOMER_ENQUIRES_CREATE,
    PERMISSION_LEVEL_MICRO_SERVICE.CUSTOMER_ENQUIRES_UPDATE,

    PERMISSION_LEVEL_MICRO_SERVICE.CUSTOMER_ORDER_VIEW,
    PERMISSION_LEVEL_MICRO_SERVICE.CUSTOMER_ORDER_UPDATE,

    PERMISSION_LEVEL_MICRO_SERVICE.APPOINTMENTS_VIEW,
    PERMISSION_LEVEL_MICRO_SERVICE.APPOINTMENTS_UPDATE,
    PERMISSION_LEVEL_MICRO_SERVICE.APPOINTMENTS_CREATE,
    PERMISSION_LEVEL_MICRO_SERVICE.APPOINTMENTS_DEACTIVATE,

    PERMISSION_LEVEL_MICRO_SERVICE.SERVICE_AND_PRODUCT_VIEW,

    PERMISSION_LEVEL_MICRO_SERVICE.PACKAGES_VIEW,

    PERMISSION_LEVEL_MICRO_SERVICE.SPECIALISTS_VIEW
  ],
  // OFFLINE_ACCESS: [PERMISSION_LEVEL_MICRO_SERVICE.DASHBOARD_VIEW],
  PHLEBO_TEAM: [
    PERMISSION_LEVEL_MICRO_SERVICE.DASHBOARD_VIEW,

    PERMISSION_LEVEL_MICRO_SERVICE.CUSTOMER_PROFILE_INFO_VIEW,
    PERMISSION_LEVEL_MICRO_SERVICE.CUSTOMER_PROFILE_INFO_CREATE,

    PERMISSION_LEVEL_MICRO_SERVICE.CUSTOMER_APPOINTMENT_VIEW,

    PERMISSION_LEVEL_MICRO_SERVICE.CUSTOMER_ENQUIRES_CREATE,

    PERMISSION_LEVEL_MICRO_SERVICE.APPOINTMENTS_VIEW,

    PERMISSION_LEVEL_MICRO_SERVICE.SERVICE_AND_PRODUCT_VIEW,

    PERMISSION_LEVEL_MICRO_SERVICE.PACKAGES_VIEW,

    PERMISSION_LEVEL_MICRO_SERVICE.SPECIALISTS_VIEW
  ],
  // UMA_AUTHORIZATION: [PERMISSION_LEVEL_MICRO_SERVICE.DASHBOARD_VIEW],
  ADMIN: [
    PERMISSION_LEVEL_MICRO_SERVICE.DASHBOARD_VIEW,

    PERMISSION_LEVEL_MICRO_SERVICE.CUSTOMER_PROFILE_INFO_VIEW,
    PERMISSION_LEVEL_MICRO_SERVICE.CUSTOMER_PROFILE_INFO_CREATE,
    PERMISSION_LEVEL_MICRO_SERVICE.CUSTOMER_PROFILE_INFO_UPDATE,
    PERMISSION_LEVEL_MICRO_SERVICE.CUSTOMER_PROFILE_INFO_DEACTIVATE,

    PERMISSION_LEVEL_MICRO_SERVICE.CUSTOMER_APPOINTMENT_VIEW,
    PERMISSION_LEVEL_MICRO_SERVICE.CUSTOMER_APPOINTMENT_CREATE,
    PERMISSION_LEVEL_MICRO_SERVICE.CUSTOMER_APPOINTMENT_UPDATE,

    PERMISSION_LEVEL_MICRO_SERVICE.CUSTOMER_ENQUIRES_VIEW,
    PERMISSION_LEVEL_MICRO_SERVICE.CUSTOMER_ENQUIRES_CREATE,
    PERMISSION_LEVEL_MICRO_SERVICE.CUSTOMER_ENQUIRES_UPDATE,

    PERMISSION_LEVEL_MICRO_SERVICE.CUSTOMER_ORDER_VIEW,
    PERMISSION_LEVEL_MICRO_SERVICE.CUSTOMER_ORDER_UPDATE,

    PERMISSION_LEVEL_MICRO_SERVICE.CUSTOMER_PATIENT_VIEW,

    PERMISSION_LEVEL_MICRO_SERVICE.APPOINTMENTS_VIEW,
    PERMISSION_LEVEL_MICRO_SERVICE.APPOINTMENTS_UPDATE,
    PERMISSION_LEVEL_MICRO_SERVICE.APPOINTMENTS_CREATE,
    PERMISSION_LEVEL_MICRO_SERVICE.APPOINTMENTS_DEACTIVATE,

    PERMISSION_LEVEL_MICRO_SERVICE.SERVICE_AND_PRODUCT_VIEW,
    PERMISSION_LEVEL_MICRO_SERVICE.SERVICE_AND_PRODUCT_UPDATE,
    PERMISSION_LEVEL_MICRO_SERVICE.SERVICE_AND_PRODUCT_CREATE,
    PERMISSION_LEVEL_MICRO_SERVICE.SERVICE_AND_PRODUCT_DEACTIVATE,

    PERMISSION_LEVEL_MICRO_SERVICE.PACKAGES_VIEW,
    PERMISSION_LEVEL_MICRO_SERVICE.PACKAGES_UPDATE,
    PERMISSION_LEVEL_MICRO_SERVICE.PACKAGES_CREATE,
    PERMISSION_LEVEL_MICRO_SERVICE.PACKAGES_DEACTIVATE,

    PERMISSION_LEVEL_MICRO_SERVICE.SPECIALISTS_VIEW,
    PERMISSION_LEVEL_MICRO_SERVICE.SPECIALISTS_UPDATE,
    PERMISSION_LEVEL_MICRO_SERVICE.SPECIALISTS_CREATE,
    PERMISSION_LEVEL_MICRO_SERVICE.SPECIALISTS_DEACTIVATE
  ],
  DEMO_USER: [
    PERMISSION_LEVEL_MICRO_SERVICE.DASHBOARD_VIEW,

    PERMISSION_LEVEL_MICRO_SERVICE.CUSTOMER_PROFILE_INFO_VIEW,
    PERMISSION_LEVEL_MICRO_SERVICE.CUSTOMER_PROFILE_INFO_CREATE,
    PERMISSION_LEVEL_MICRO_SERVICE.CUSTOMER_PROFILE_INFO_UPDATE,
    PERMISSION_LEVEL_MICRO_SERVICE.CUSTOMER_PROFILE_INFO_DEACTIVATE,

    PERMISSION_LEVEL_MICRO_SERVICE.CUSTOMER_APPOINTMENT_VIEW,
    PERMISSION_LEVEL_MICRO_SERVICE.CUSTOMER_APPOINTMENT_CREATE,
    PERMISSION_LEVEL_MICRO_SERVICE.CUSTOMER_APPOINTMENT_UPDATE,

    PERMISSION_LEVEL_MICRO_SERVICE.CUSTOMER_ENQUIRES_VIEW,
    PERMISSION_LEVEL_MICRO_SERVICE.CUSTOMER_ENQUIRES_CREATE,
    PERMISSION_LEVEL_MICRO_SERVICE.CUSTOMER_ENQUIRES_UPDATE,

    PERMISSION_LEVEL_MICRO_SERVICE.CUSTOMER_ORDER_VIEW,

    PERMISSION_LEVEL_MICRO_SERVICE.CUSTOMER_PATIENT_VIEW,
    PERMISSION_LEVEL_MICRO_SERVICE.CUSTOMER_PATIENT_UPDATE,
    PERMISSION_LEVEL_MICRO_SERVICE.CUSTOMER_PATIENT_CREATE,

    PERMISSION_LEVEL_MICRO_SERVICE.APPOINTMENTS_VIEW,
    PERMISSION_LEVEL_MICRO_SERVICE.APPOINTMENTS_UPDATE,
    PERMISSION_LEVEL_MICRO_SERVICE.APPOINTMENTS_CREATE,
    PERMISSION_LEVEL_MICRO_SERVICE.APPOINTMENTS_DEACTIVATE,

    PERMISSION_LEVEL_MICRO_SERVICE.SERVICE_AND_PRODUCT_VIEW,
    PERMISSION_LEVEL_MICRO_SERVICE.SERVICE_AND_PRODUCT_UPDATE,
    PERMISSION_LEVEL_MICRO_SERVICE.SERVICE_AND_PRODUCT_CREATE,
    PERMISSION_LEVEL_MICRO_SERVICE.SERVICE_AND_PRODUCT_DEACTIVATE,

    PERMISSION_LEVEL_MICRO_SERVICE.PACKAGES_VIEW,
    PERMISSION_LEVEL_MICRO_SERVICE.PACKAGES_UPDATE,
    PERMISSION_LEVEL_MICRO_SERVICE.PACKAGES_CREATE,
    PERMISSION_LEVEL_MICRO_SERVICE.PACKAGES_DEACTIVATE,

    PERMISSION_LEVEL_MICRO_SERVICE.SPECIALISTS_VIEW,
    PERMISSION_LEVEL_MICRO_SERVICE.SPECIALISTS_UPDATE,
    PERMISSION_LEVEL_MICRO_SERVICE.SPECIALISTS_CREATE,
    PERMISSION_LEVEL_MICRO_SERVICE.SPECIALISTS_DEACTIVATE
  ]
}

export const PAYMENT_MODE = [
  { id: 1, name: 'MPESA/AIRTEL', value: 0 },
  { id: 2, name: 'PAYPAL/ONLINE', value: 5 },
  { id: 3, name: 'PESAPAL/ONLINE', value: 3.5 },
  { id: 4, name: 'BANK TRANSFER', value: 0 },
  { id: 5, name: 'FLUTTERWAVE', value: 3.5 },
  { id: 6, name: 'INSURANCE PAY', value: 0 },
  { id: 7, name: 'CORPORATE WALLET', value: 0 },
  { id: 8, name: 'CASH PAYMENT', value: 0 },
  { id: 9, name: 'CREDIT NOTE', value: 0 },
  { id: 10, name: 'ASPIRA-FINANCING', value: 0 }
]
